.weeks-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
 padding: 40px 30px ;
}

.week-tile {
  font-weight: bold;
  background-color: #f0f0f0;
  text-align: left;
  display: flex;
  padding: 15px;
  border-radius: 10px;
  background: #F4F4F4;
  box-shadow: 7.13px 5.35px 7.13px 0px #44444426;
  width:270px;
  gap:20px;
  cursor:pointer;
}
.week-tile:hover{
  box-shadow: 2px 2px 7.13px 0px #44444426;
}
.week-tile.disabled {
  color: #aaa; /* Light color for disabled weeks */
  cursor: not-allowed;
}



#scroll-container-weekly-report{
    height: 100%;
    scroll-snap-type: y mandatory;
    overflow: scroll;
    display:flex;
    flex-direction: column;
    scroll-behavior: smooth;
}
#scroll-item-weekly-report{
    margin: 0;
    scroll-snap-align: start;
    background-color: #FCFCFC;
    border-radius: 10px;
}
.custom-datepicker-input{
  position: relative;
  background-color: white;
  padding: 5px 10px;
  color: #68717E;
  border: 1px solid #C9C9C9;
  border-radius: 7px;
  width: 230px;
  box-shadow: 0px 2px 4px 0px #4250661A;
}