.reusable-table-containar {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
}

.reusable-table-title-container {
    font-weight: 500;
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    width: 100%;
    margin-top: 15px;
}

.reusable-table-no-title-container {
    display: none;
}

.reusable-table-title {
    color: rgb(8, 20, 37);
    font-weight: 600;
    font-size: 23px;
}

.reusable-table-view {
    width: 100%;
    box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 100%;
}

.reusable-table-view-header-row {
    font-family: Lato !important;
    border: none;
    /* border-radius: 4px; */
    /* background: #FFFFFF; */
    height: 50px;
    font-size: 12px;
    background-color: #E1EDFF !important;
    /* color: #3c3c3c !important; */
    color: #42454a;
    position: sticky;
    top: 0;
    z-index: 8;
}

.reusable-table-view-header-row th {
    font-family: Lato !important;
    /* border-radius: 4px; */
    padding-left: 23px;
    padding-right: 23px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    /* color: #334D6E; */
    color: black;
    /* opacity: 0.5; */
    text-align: left;
}

.reusable-table-view-data-row-link {
    border: none;
    border-radius: 4px;
    background: #fcfcfc;
    /* height: 40px !important; */
    display: table-row;
    height: 50px;
    cursor: pointer;
    vertical-align: middle;
}

.reusable-table-view-data-row-link td {
    text-align: left;
    font-family: Lato !important;
    text-decoration: none;
    /* border-radius: 4px; */
    padding-left: 23px;
    padding-right: 23px;
    border-bottom: solid 1px #E1EDFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #68717E;
}

.reusable-table-view-data-row {
    font-family: Lato !important;
    border: none;
    border-radius: 4px;
    background: #fcfcfc;
    /* height: 40px !important */
    height: 45px
}

.reusable-table-view-data-row td {
    text-decoration: none;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: solid 1px #e0e0e0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #3c3c3c;
}

.detailed-log-table-height {
    height: calc(100% - 460px)
}

.extra-table-row {
    height: 100%
}

.rmdetailed-log-table-height {
    height: calc(100% - 430px);
}

.rmp-flight-status-logs-table-height {
    height: calc(100% - 100px);
}

.row-hover:hover {
    background: #edf6ff;
}

@media screen and (max-width:1919px) {
    .reusable-table-title {
        font-size: 18px;
    }

    .reusable-table-view-header-row {
        height: 40px;
    }

    .reusable-table-view-header-row th {
        font-size: 11px;
    }

    .reusable-table-view-data-row-link td {
        font-size: 11px;
    }

    .reusable-table-view-data-row-link {
        height: 40px;
    }

    .rmdetailed-log-table-height {
        height: calc(100% - 410px);
    }

    .rmp-flight-status-logs-table-height {
        height: calc(100% - 110px);
    }

    .drn-mgmt-drones-table-height {
        height: calc(100% - 75px);
    }
}