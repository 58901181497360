.replay_fligths_wrapper {
    z-index: 12;
    /* height: calc(100% - 56px); */
    position: fixed;
    right: 0px;
    width: 0px;
    background: white;
    transition: width 0.5s ease;
}

.replay_fligths_wrapper.open {
    position: fixed;
    right: 0;
    height: calc(100% - 55px);
    width: 300px;
    z-index: 12;
    background: white;
    transition: width 0.5s ease;
}

.close_btn {
    background: white;
    position: absolute;
    z-index: 11;
    opacity: 1;
    cursor: pointer;
    padding: 0px 5px;
    top: 75px;
    transition: all 0.5s ease-in-out;
}

.close_btn.openBtn {
    right: 505px;
}

.close_btn.closeBtn {
    right: 5px;
}

.replay_fligths_videos {
    height: 830px;
    overflow: auto;
    padding: 10px;
    gap: 10px;
}

.replay_fligths_title {
    padding: 10px 8px;
    height: 50px;

}

.single_replay_wrapper {
    font-size: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 300px !important;
    gap: 0.5rem;

}

.replay_first_section {
    width: 5%;
}

.replay__second__section {
    width: 40%;
}

.replay__second__section img {
    width: 150px;
    height: 100px;
    border-radius: 10px;
}

.replay__third__section {
    width: 50%;
}

.video_time {
    font-size: 12px;
    position: relative;
    z-index: 10;
    width: fit-content;
    top: -25px;
    right: -100px;
    padding: 2px 8px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    background-color: black;
    opacity: 0.8;
}

@media (max-width:1900px) {
    .replay_fligths_wrapper {
        top: 55px;
        height: calc(100% - 56px);
    }

    .close_btn {
        top: 60px;
    }
}