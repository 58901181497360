/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
body{
  width: 100%;
  height: 100%;
  background-color: #F3F3F3;
  margin-top: 20px;
  overflow: hidden;
} */

.filters__section {
  font-family: Lato;
  width: 100%;
  padding: 18px 30px;
  background: #FCFCFC;
  margin-top: 15px;
  margin-right: auto;
  border-radius: 10px 10px 0px 0px;
  margin-top: 15px;
}

.first_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;

}

.second__section {
  margin-top: 4px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 25px;
}

.Drones__Pilots__select {
  display: flex;
  align-items: center;
  width: calc(100% - 200px);
  gap: 35px;
}

.duration_selectors {
  width: 30%;
  display: flex;
  justify-content: end;
}

.duration_selectors button {
  width: 100px;
  border: none;
  padding: 9px !important;
  border-top: 1px solid #C9C9C9;
  border-bottom: 1px solid #C9C9C9;
  background: white;
  line-height: 1.35;
}

.duration_selectors>:first-child {
  border-radius: 5px 0px 0px 5px;
}

.duration_selectors>:last-child {
  border-radius: 0px 5px 5px 0px;
}

.duration_selectors button.active {
  background-color:
    rgba(57, 136, 249, 1);
  border: none;
  color: white;
  line-height: 1.4;
}

.pilots_wrapper {
  min-width: 27%;
  width: 27%;
}

.select_dropdown_label {
  color: rgba(104, 113, 126, 1);
  font-size: 15px;
  letter-spacing: 0.5px;
  overflow: hidden;
}


.dates__section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.dates__section span {
  color: rgba(104, 113, 126, 1);
  font-size: 16px;
  letter-spacing: 0.5px;
}


.dates__wrapper {
  width: 100%;
  border: 1px solid #C9C9C9;
  padding: 8px 10px;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  position: relative !important;
  gap: 0px;
  background-color: white;
}


.single__date {
  display: flex;
  align-items: center;
  width: 45%;
  margin-left: 8px;
  gap: 10px;
  cursor: pointer;
}

.single__date span {
  letter-spacing: -0.1px;
  color: rgba(8, 20, 37, 1);
}

.hidden {
  display: none;
}


.single__date span {
  letter-spacing: -0.3px;
}


.arrow__icon {
  width: 10%;
  display: flex;
  justify-content: center;
}

.date_time_wrapper {
  display: flex;
  gap: 11px;
  align-items: center;
}


.react-datepicker-popper {
  width: 330px;
}

.react-datepicker-wrapper-custom-1 {
  position: absolute !important;
  z-index: 10;
  min-width: 10px;
  left: 25%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.react-datepicker-wrapper-custom-2 {
  position: absolute !important;
  z-index: 10;
  min-width: 10px;
  left: 75%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.filters__search {
  background: #F4F4F4;
  padding: 8px 15px 8px 10px;
  border-radius: 7px;
  border: 1px solid #c9c9c9;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 705px;

}

.filters__search img {
  width: 20px;
  opacity: 0.8;
}

.stats {
  display: flex;
  width: 70%;
  align-content: center;
  margin-top: 8px;
  max-height: 100px;
  gap: 0.6rem;
  font-family: "Lato", sans-serif !important;

}

.stat_card {
  display: flex;
  margin-top: 15px;
  gap: 0.6rem;
}

.stat_card span {
  font-size: 17px;
}

.stat_card p {
  font-size: 27px;
  margin-top: -9px;
  font-weight: 600;
}

.separator {
  width: 25px;
  border-left: 2px solid #A8ACB1;
  height: 33px;
  margin-top: 10px;
}

.undefined_date_wrapper {
  margin-left: 5px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.undefined_date {
  font-size: 17px !important;
  letter-spacing: 2px !important;
}

.undefined_time {
  letter-spacing: 2px !important;
  font-size: 17px !important;
}

@media screen and (max-width:1919px) {
  .filters__search {
    background: #F4F4F4;
    padding: 2px 15px 2px 10px;
    border-radius: 5px;
    border: 1px solid #c9c9c9;
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 500px;
  }

  .filters__search img {
    width: 16px;
  }

  .first_section input::placeholder {
    font-size: 11px;
  }

  .duration_selectors button {
    font-size: 12px;
    width: 70px;
    padding: 6px !important;
  }

  .custom_dropdown .react-dropdown-select {
    padding: 0px 10px;
  }

  .second__section {
    margin-top: 5px;
  }


  .single__date {
    gap: 4px;
  }

  .single__date span {
    font-size: 11px;
  }

  .single__date img {
    width: 14px;
  }

  .dates__wrapper {
    padding: 7px;
    gap: 15px;
  }

  .dates__section span {
    font-size: 11px;
  }

  .dates__section {
    display: block;
    max-width: 350px;
  }

  .arrow__icon img {
    width: 12px;
  }

  .select_dropdown_label {
    font-size: 11px;
  }

  .stat_card span {
    font-size: 12px;
  }

  .stat_card p {
    font-size: 16px;
    margin-top: -5px;
    font-weight: 600;
  }

  .separator {
    width: 15px;
    height: 26px;
  }

  .date_time_wrapper {
    gap: 5px;
  }

  .undefined_date_wrapper {
    margin-left: 5px;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .undefined_date {
    font-size: 15px !important;
    letter-spacing: 2px !important;
  }

  .undefined_time {
    letter-spacing: 2px !important;
    font-size: 15px !important;
  }

}