.overview_box {
    height: 96px;
}

.overview_box_container {
    height: 96px;
    width: 286px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.overview_box_container:hover {
    background-color: rgb(244, 244, 255);
}

.overview_box_data {
    display: flex;
    align-items: center;
    padding: 16px 0px 16px 16px;
    gap: 20px;
}

.overview_box_data_container {
    margin-bottom: 6px;
    line-height: 22px;
}

.overview_box_image {
    background-color: #868F9C;
    padding: 13px;
    border-radius: 8px;
}

.overview_box_title {
    color: #081425;
    font-weight: 500;
    font-size: 20px;
}

.overview_box_subtitle {
    color: #68717E;
    font-size: 15px;
}

.overview_box_time {
    color: #68717E;
    font-size: 13px;
}

.overviewBox_action_container {
    /* padding: 5px 15px; */
    cursor: pointer;
}

@media screen and (max-width:1919px) {
    .overview_box_container {
        height: 75px;
        width: 210px;
        border-radius: 5px;
    }
    .overview_box_data{
        padding: 10px 0px 10px 10px;
        gap: 10px;
    }
    .overview_box_image {
        padding: 10px;
        border-radius: 5px;
    }

    .overview_box_image img {
        width: 29px;
    }
    .overview_box_title{
        font-size: 14px;
    }
    .overview_box_subtitle{
        font-size: 12px;
    }
    .overview_box_time{
        font-size: 10px;
    }
    .overview_box_data_container{
        margin-bottom: 5px;
        line-height: 16px;
    }
    .overview_box{
        height: 77px;
    }
}