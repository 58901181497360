#page
{ padding-top:0px;
    background:#F5F6F8;
}
.service-details
{
    width:100%;
    display:flex;
    background:#EBEFF2;
    height:247px;
}
.service-image
{
    width:200px;
    height:200px; 
    padding: 22px 22px 22px 22px;
    margin-top: 21px;
}
.service-content
{
    margin-top:46px;
    padding-left:22px;
}
.service-description
{
/* width: 327px;
height: 154px;
padding: 15px 15px 15px 0px;
Font Family: Avenir Next LT Pro
font-style: Regular;
font-size: 13px;*/
} 