.drone-performance-progress-bar{
  width: calc(100% - 115px);
  height: 20px;
  border-radius: 50px;
  background: rgb(235, 163, 3);
}
.drone-performance-hours{
  display: block;
  width: 75px;
  text-align: right;
}
.drone-performance-drone-name{
  margin-top: 2px;

}
.table-box-large-header{
  width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 18px 30px 18px 30px;
}
@media screen and (max-width:1919px) {
    
  .table-box-large {
    height: 30vh;
    padding: 10px 25px;
    min-height: 265px;
    
  }
  .drone-performance-progress-bar{

    height: 15px;
  }
  .drone-performance-hours{
    display: block;
    width: 75px;
    text-align: right;
    font-size: 10px;
  }
  .drone-performance-drone-name{
    font-size: 10px;
  }
  .table-box-large-header{
    padding: 10px 25px;
  }  
  .flights-table th{
    font-size: 12px;
  }
  .flights-table td{
    height: 45px;
    font-size: 11px;

  }
}
