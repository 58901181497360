.switch_btn_container {
    display: flex;
    height: 35px;
    width: 150px;
    align-items: center;
    border: 1px solid #EEEEEA;
    border-radius: 10px;
}

.switch_btn_active {
    cursor: pointer;
    display: flex;
    height: 35px;
    width: 50%;
    align-items: center;
    background-color: #3988F9;
    color: #FEFEFE;
    justify-content: center;
    font-weight: 500;
}

.switch_btn_inactive {
    cursor: pointer;
    display: flex;
    height: 35px;
    width: 50%;
    align-items: center;
    color: #081425;
    background-color: #FEFEFE;
    justify-content: center;
    font-weight: 500;
}