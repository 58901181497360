.flight-card {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    font-family: Lato;
    overflow: hidden;
    position: relative;
    opacity: 100%;
}

.fligh-card-disabled {
    filter: grayscale(100%);
    opacity: 70%;
}

.flight-card-icon {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flight-card-title {
    color: rgb(51, 51, 51);
    font-size: 15px;
    height: 20px;
    display: flex;
    align-items: center;
}

.flight-card-count {
    font-size: 28px;
    font-weight: 600;
    color: rgb(35, 35, 35);
    height: 20px;
    display: flex;
    align-items: center;
}

.flight-card-info-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
}

.flight-card-left-border {
    width: 15px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
}

.flight-card {
    border-radius: 10px;
    transition: 0.5s all;
    padding: 10px 20px 10px 35px;
}

.hover-flight-card {
    border-radius: 10px;
    transition: 0.5s all;
    box-shadow: 0px 0px 4px 0px #0000001A;
    padding: 10px 20px 10px 35px;
  }
  
  .hover-flight-card.flight-flights:hover {
    box-shadow: 0px 0px 15px 4px #4285F440;
  }
  
  .hover-flight-card.flight-acres:hover {
    box-shadow: 0px 0px 15px 4px #718D4A40;
  }
  
  .hover-flight-card.flight-kilometers:hover {
    box-shadow: 0px 0px 15px 4px #EBA30340;
  }
  
  .hover-flight-card.flight-pilots:hover {
    box-shadow: 0px 0px 15px 4px #6868B240;
  }
  
  .hover-flight-card.flight-livestream:hover {
    box-shadow: 0px 0px 15px 4px #CF332940;
  
  }
  @media screen and (max-width:1919px) {
    
    .flight-card-count{
      font-size: 17px;
    }
    .hover-flight-card{
      border-radius: 8px;
    }
    .flight-card-left-border {
      width: 10px;
    }
    .flight-card-info-container{
      gap: 25px;
    }
    .flight-card-title{
      font-size: 11px;
    }
    .hover-flight-card{
      padding: 10px 20px 10px 25px;
    }
    
  }