.stat-container {
    margin-top: 50px;
}

.stat-container-title {
    display: flex;
    align-items: center;
    font-size: 23px;
    color: #081425;
    font-weight: 600;
    margin-bottom: 20px;
}

.vertical-seperator {
    height: 30px;
    width: 2px;
    background: #8d8d8d;
    margin: 0px 20px;
}

.stat-card-container {
    display: flex;
    justify-content: space-between;
    height: 110px;
    gap: 50px;
}

.flight-graph-container-new {
    margin: 14px 0px;
    position: relative;
    width: 100%;
    background: #fcfcfc;
    padding: 10px 30px;
    box-shadow: 0px 0px 4px 0px #0000001A;
    height: calc(100% - 200px);
}

.flight-graph-container-title {
    font-weight: 600;
    font-size: 18px;
}

.graphSectionTitle {
    font-weight: 600;
    font-size: 23px;
}

.flight-graph-time-selector {
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 10;
}

@media screen and (max-width:1919px) {
    .stat-container {
        margin-top: 35px;
    }

    .stat-container-title {
        font-size: 17px;
    }

    .stat-card-container {
        height: 85px;
        border-radius: 6px;
        gap: 30px;
    }

    .vertical-seperator {
        height: 20px;
    }

    .flight-graph-container-title {
        font-size: 14px;
    }

    .flight-graph-container-new {
        padding: 10px 20px;
        margin: 10px 0px;
        height: calc(100% - 188px);
    }

    .graphSectionTitle {
        font-size: 17px;
    }
}