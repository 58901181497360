.box_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(286px, 96px));
    margin: 22px 0px;
    row-gap: 30px;
    column-gap: 69px;
}

@media screen and (max-width:1919px) {
    .gcs-projects-screen .box_container {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }
    .gcs-plans-screen .box_container{
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        column-gap: 35px;
    }
}