.new-button {
    border: 1px solid #2989cf;
    border-radius: 8px;
    color: #2989cf;
    background-color: transparent;
    padding: 4px 16px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.01em;
    margin: 0px 5px 0px 5px;
    opacity: 1;
    cursor: pointer;
    height: auto;
    text-transform: capitalize;
    width: auto;
    display: block;
    min-width: 70px;
}

#new-icon {
    width: 10px;
    transform: rotate(0deg);
    transition: 0.3s all;
    padding: 0;
}

.rotate-new-icon {
    transform: rotate(180deg);
}

#new-icon-seperator {
    margin: 0px 5px 0px 5px;
}

.new-btn-active {
    color: white;
    background-color: #3988F9;
}

.warning-btn {
    border-color: #FFAB00;
    background-color: #FFAB00;
}

.error-btn {
    border-color: #E42727;
    background-color: #E42727;
}

.complete-btn {
    border-color: #59AE31;
    background-color: #59AE31;
}

.new-btn-md {
    width: 100px;
    height: 35px;
    font-size: 15px;
}

.new-btn-disabled {
    opacity: 0.5;
    filter: grayscale(100%);
    cursor: default !important;
}

.new-btn-lg {
    min-width: 104px;
    height: 39px;
    font-size: 16px;
    font-weight: 600;
    font-family: Lato;
}
.new-btn-rounded{
    min-width: 0px;
    padding: 6px;
    border-radius: 50%;
}
.new-btn-rounded #new-icon{
    width: 15px;
}
@media screen and (max-width:1919px) {
    .new-btn-lg{
        height: 32px;
        font-size: 13px;
    }
    .new-btn-rounded{
        padding: 4px;
    }
    .new-btn-rounded #new-icon{
        width: 12px;
    }
}