.main_wrapper_search {
    display: flex;
    width: 100%;
    height: 75px;
    margin-bottom: 10px;
    padding: 5px 20px;
    background: #FCFCFC;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
}


.first_section_search {
    width: 50%;
}

.second__section_search {
    width: 40%;
    display: flex;
    /* background: lightcoral; */
    justify-content: flex-end;
    align-items: center;
    padding: 5px 10px;
    gap: 1.5rem;
}


.datePikcer_search {
    width: 65%;
}


.Search_wrapper {
    width: 75%;
    display: flex;
    align-items: center;
    position: relative;
    font-family: "Lato", sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
}



.Search_wrapper input {
    width: 100%;
    padding: 9px 13px;
    outline: none;
    background-color: #F4F4F4;
    border: 1px solid #C9C9C9;
    padding-right: 25px;
    border-radius: 8px;
}

.Search_wrapper input::placeholder {
    font-family: "Lato", sans-serif !important;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.5px;
}

.icon {
    position: absolute;
    right: 10px;
}


.viewbuttons {
    display: flex;
    width: 75px;
    height: 35px;
    align-items: center;
    border: 1px solid gray;
    border-radius: 5px;
}


.listicon_wrapper {
    width: 50%;
    height: 100%;
    cursor: pointer;
    border-radius: 3px 0px 0px 3px;
}

.gridicon_wrapper {
    width: 50%;
    height: 100%;
    cursor: pointer;
    border-radius: 0px 3px 3px 0px;
}

.img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.listicon_wrapper.active,
.gridicon_wrapper.active {
    background-color: #3988F9;
}

.listicon_wrapper img,.gridicon_wrapper img{
    height: 20px;
}

@media screen and (max-width:1919px) {

    .second__section_search {
        width: 55%;
    }

    .datePikcer_search {
        width: 70%;
    }
    .Search_wrapper input{
        height: 32px;
        border-radius: 5px;
    }
    .Search_wrapper input::placeholder{
        font-size: 12px;
    }
    .viewbuttons{
        width: 65px;
        height: 30px;
    }
    .listicon_wrapper img{
        height: 16px;
    }
    .gridicon_wrapper img{
        height: 16px;
    }
}